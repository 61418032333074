<template>
  <div>
    <div class="wrap">
      <div class="top-area">
        <div class="title">Market</div>
        <!--<router-link :to="{path: `/market/membership`}">
          <span class="winex-btn primary-btn minting-btn">Membership Minting</span>
        </router-link>-->
      </div>
    </div>
    <div class="winex-wine-list-wrap">
      <div class="filter-wrap pc-filter-wrap">
        <!-- TODO :: 잠시 예외 처리 -->
        <div class="filter-title-wrap" @click="gettingReady">
          <h2 class="title">Filter</h2>
          <div class="filter" @click="getMembershipFilterList"></div>
        </div>
        <PcGroupCheckbox
          v-for="item in filterList"
          :key="item.name"
          :list="item.list"
          :title="item.name"
          :isOpen="item.open"
        />
      </div>
      <div class="wine-list-wrap">
        <div class="pc-list-wrap">
          <PcMembershipList
          :membership-n-f-t-list="viewMembershipList"
          :move-page="getMembershipList"
          :page-info="pageInfo"></PcMembershipList>
        </div>
        <div class="mobile-list-wrap">
          <div class="wine-list-mobile-header">
            <!-- TODO :: 잠시 예외 처리 -->
            <!--<div class="filter-wrap" @click="openFilterModal">-->
            <div class="filter-wrap" @click="gettingReady">
              <div class="filter-icon"></div>
              <div>Filter</div>
            </div>
            <div></div>
          </div>
          <mobile-membership-list
                  :membership-n-f-t-list="viewMembershipList"
                  :next-page="nextPage">

          </mobile-membership-list>
          <FilterModal
            :closeFilterModal="closeFilterModal"
            :isOpen="filterModalIsOpen"
            :filterList="filterList"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import getContractApi from '../../api/contract';
// import {
//   firstWineDummyList,
//   secondWineDummyList,
// } from '../../assets/dummy/nftFilterDummyList';
import PcGroupCheckbox from '../../components/checkbox/pcGroupCheckbox';
import FilterModal from '../../components/modals/FilterModal.vue';
import PcMembershipList from "../../components/list/PcMembershipList";
import MobileMembershipList from "../../components/list/MobileMembershipList";

export default {
  components: {MobileMembershipList, PcMembershipList,   PcGroupCheckbox, FilterModal },
  created() {
    this.market = getContractApi().getMembershipMarket();
  },
  mounted() {
    // @TODO 로딩 중 화면
    (async () => {
      this.viewMembershipList = [];
      for await (const [info] of this.updatePageItems(1)) {
        this.viewMembershipList.push(info);
      }
      // @TODO 로딩 완료
    })();

    this.market
      .fetchCountListedItems()
      .then((count) => {
        // console.log(count)
        const step = this.pageInfo.item_per_page;
        const total = Math.ceil(count.toNumber() / step);
        this.$set(this.pageInfo, 'total_page', total);
      });

    this.getMembershipFilterList();
  },
  data() {
    return {
      contract: null,
      viewMembershipList: [],
      filterList: [],
      membershipList: [],
      filterModalIsOpen: false,
      // firstMembershipList: firstMembershipDummyList,
      // secondMembershipList: secondMembershipDummyList,
      pageInfo: {
        current_page: 1,
        total_page: 100,
        item_per_page: 9,
      },
    };
  },
  methods: {
    getMembershipFilterList() {
      this.$store
        .dispatch('wineFilterList/getMembershipFilterList', {})
        .then((data) => {
          this.filterList = data;
        });
    },
    openFilterModal() {
      this.filterModalIsOpen = true;
    },
    closeFilterModal() {
      this.filterModalIsOpen = false;
    },
    gettingReady() {
      alert('준비중 입니다.');
    },
    getMembershipList(page) {
      // 통신 처리
      if (page !== this.pageInfo.current_page) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      this.pageInfo.current_page = page;
      // @TODO 로딩 중 화면
      (async () => {
        this.viewMembershipList = [];
        for await (const [info] of this.updatePageItems(page)) {
          this.viewMembershipList.push(info);
        }
      })().finally(() => {
        // @TODO 로딩 완료
      });
    },
    nextPage($state) {
      // https://peachscript.github.io/vue-infinite-loading/guide/start-with-hn.html
      // 통신 연동 부분
      if (this.pageInfo.current_page < this.pageInfo.total_page) {
        const page = this.pageInfo.current_page;
        (async () => {
          for await (const [info, i] of this.updatePageItems(page)) {
            if (i < this.viewMembershipList.length) {
              this.$set(this.viewMembershipList, i, info);
            } else {
              this.viewMembershipList.push(info);
            }
          }
          this.pageInfo.current_page++;
        })().finally(() => {
          $state.loaded(); // 다음 페이지가 있을 경우
        });
      } else {
        $state.complete(); // 다음 페이지가 없을 경우
      }
    },
    async *updatePageItems(page) {
      const start = (page - 1) * this.pageInfo.item_per_page;
      const end = start + this.pageInfo.item_per_page;
      if (this.membershipList.length < end) this.membershipList.length = end;

      const pattern = /https?:\/\/[^/]+\/ipfs\/(.+)/;
      for (let i = start; i < end; ++i) {
        let item = this.membershipList[i];
        if (item === undefined) {
          if (!(item = await this.market.fetchItemListed(i))) {
            const step = this.pageInfo.item_per_page;
            const total = Math.ceil(i / step);
            this.$set(this.pageInfo, 'total_page', total);
            break;
          }
          const matched = item.image?.match(pattern);
          if (matched) {
            item.fallbackImage = item.image;
            item.image = `https://winex-s3.s3.ap-northeast-2.amazonaws.com/ipfs/${matched[1]}`;
          }
          this.$set(this.membershipList, i, item);
        }
        yield [item, i];
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/nft-list.scss';
</style>
